.input-component-wrapper {
  margin-bottom: 24px;

  .input-wrapper {
    position: relative;
    display: block;
    color: #787e8e;

    .label {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-60%);
      transition: all 250ms ease-out;

      font-size: 16px;
      font-weight: 400;
      color: white;
      text-transform: capitalize;

      cursor: text;
    }

    input {
      width: 100%;
      height: 40px;
      padding: 4px 16px;
      border: 1px solid black;
      border-radius: 10px;

      font-size: 16px;
      background-color: #454545;

      transition: border-color 250ms ease-in-out;

      &:focus {
        border-color: black;
        outline: none;
      }

      &:not(:placeholder-shown) ~ .label,
      &:focus ~ .label {
        font-size: 12px;
        color: black;

        top: -8px;
        background-color: #454545;
        transform: translateY(0);
        margin: 0;
        padding: 0 3px;
      }
    }
  }
}

.fpactionwindow_grid {
    display: grid;
    grid-gap: 6px;
    grid-t-rows: [row] auto [row] auto [row];
    align-items: end;
    color: white;
    font-size: 10px;
    text-align: center;
    margin: 1px 6px;
}

.fpactionwindow_grid_firstline {
    grid-template-columns: 70px 120px 20px 35px 14px 40px 40px 188px 20px 160px 45px 12px 12px;
}

.fpactionwindow_grid_secondline {
    grid-template-columns: 63px 650px 63px;
}

.fpactionwindow_grid_thirdline {
    grid-template-columns: 55px 35px 30px 35px 30px 35px 35px 40px 40px 40px 40px 40px 40px 40px 75px;
}

.fpactionwindow_grid_fourthline {
    margin-top: 4px;
    grid-template-columns: 5% 94.3%;
}

.fpactionwindow_grid_fifthline {
    grid-template-columns: 165px 65px 65px 222px 65px 165px 65px;
}

.fpactionwindow_grid_sixthline {
    margin-top: 4px;
    grid-template-columns: 10% 89.3%;
}

.fpactionwindow_grid_seventhline {
    display: flex;
    margin-top: 7px;
    color: white;
    font-size: 10px;
    text-align: left;
}

.fpactionwindow_grid input {
    font-size: 12px;
    width: 100%;
    color: black;
    border-radius: 3px;
    border: none;
    text-transform: uppercase;
}

.error-field {
    background-color: red;
}

.routerLink {
  text-decoration: none;
  background-color: rgb(73, 70, 70);
  color: rgb(193, 193, 193);
  padding: 14px 25px;
  text-align: center;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
}
.routerLink:hover {
  background-color: rgb(127, 127, 127);
}
.headerContainer {
  display: grid;
  grid-template-columns: 300px auto 100px;
}

.homeLinkButton {
  margin-bottom: 2px;
  font-size: 22px !important;
  height: 32px !important;
  line-height: 33px !important;
  width: 290px !important;
}
